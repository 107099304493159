var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"components-content"},[_c('div',{staticClass:"placed-the-top-dynamic",on:{"click":function($event){return _vm.$router.push({
        name: 'DynamicDetails',
        params: {
          id: _vm.topData.id,
          pid: 9
        }
      })}}},[_c('div',{staticClass:"placed-the-top-dynamic-cover"},[_c('img',{attrs:{"src":_vm.topData.head_pic,"alt":""}})]),_c('div',{staticClass:"placed-the-top-dynamic-info"},[_c('div',{staticClass:"placed-the-top-dynamic-title"},[_vm._v(" "+_vm._s(_vm.topData.title)+" ")]),(_vm.topData.content)?_c('div',{staticClass:"placed-the-top-dynamic-intro",domProps:{"innerHTML":_vm._s(_vm.topData.content.replace(/<[^>]+>/g, ''))}}):_vm._e(),_c('div',{staticClass:"placed-the-top-dynamic-time-box frcb"},[_c('div',{staticClass:"placed-the-top-dynamic-time"},[_vm._v(" "+_vm._s(_vm.topData.create_time)+" ")]),_vm._m(0)])])]),_c('div',{staticClass:"dynamic-list-wrapper"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"dynamic-list-item frcb",on:{"click":function($event){return _vm.$router.push({
            name: 'DynamicDetails',
            params: {
              id: item.id,
              pid: 9
            }
          })}}},[_c('div',{staticClass:"dynamic-list-item-time fcc"},[_c('div',{staticClass:"dynamic-list-item-time-day"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.create_time,"DD"))+" ")]),_c('div',{staticClass:"dynamic-list-item-time-YM"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.create_time,"YYYY-MM"))+" ")])]),_c('div',{staticClass:"dynamic-list-item-info"},[_c('div',{staticClass:"dynamic-list-item-info-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"dynamic-list-item-info-intro",domProps:{"innerHTML":_vm._s(item.content.replace(/<[^>]+>/g, ''))}})])])}),0)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dynamic-arrow-icon"},[_c('img',{attrs:{"src":require("@/assets/images/dynamic_arrow.png"),"alt":""}})])}]

export { render, staticRenderFns }