<template>
  <div class="components-content" id="html-content" v-html="content"></div>
</template>

<script>
import { fetchSchoolAbout } from "@/api/school.js";
export default {
  name: "SchoolAbout",
  data() {
    return {
      content: ""
    };
  },
  mounted() {
    this.getAbout();
  },
  methods: {
    async getAbout() {
      const { result } = await fetchSchoolAbout();
      this.content = result.lesson;
    }
  }
};
</script>

<style lang="less" scoped>
.survey-about {
  padding: 6vw 3vw;
}
</style>

<style lang="less">

</style>
