<template>
  <div class="components-content">
    <div
      class="placed-the-top-project"
      @click="
        $router.push({
          name: 'SchoolBrandProjectDetails',
          params: {
            id: topData.id,
            pid: 29
          }
        })
      "
    >
      <div class="placed-the-top-project-cover">
        <img :src="topData.head_pic" alt="" />
      </div>
      <div class="placed-the-top-project-title">
        {{ topData.title }}
      </div>
      <div
        class="placed-the-top-project-intro"
        v-if="topData.content"
        v-html="topData.content.replace(/<[^>]+>/g, '')"
      ></div>
      <div class="placed-the-top-project-time-bar frcb">
        <div class="placed-the-top-project-time">
          {{ topData.create_time }}
        </div>
        <div class="placed-the-top-project-more frc">
          <div class="more">更多</div>
          <img
            class="more-iocn"
            src="@/assets/images/news_right_arrow.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="brand-project-list">
        <div
          class="brand-project-item"
          v-for="(item, index) in list"
          :key="index"
          @click="
            $router.push({
              name: 'SchoolBrandProjectDetails',
              params: {
                id: item.id,
                pid: 29
              }
            })
          "
        >
          <div class="brand-project-item-cover">
            <img :src="item.head_pic" alt="" />
          </div>
          <div class="brand-project-item-title">
            {{ item.title }}
          </div>
          <div class="brand-project-item-time">
            {{ item.create_time }}
          </div>
          <div
            class="brand-project-item-intro"
            v-html="item.content.replace(/<[^>]+>/g, '')"
          ></div>
          <div class="placed-the-top-project-more frc">
            <div class="more">更多</div>
            <img
              class="more-iocn"
              src="@/assets/images/news_right_arrow.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { fetchSchoolBrandProject } from "@/api/school.js";
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      topData: {}
    };
  },
  methods: {
    async onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      const { result } = await fetchSchoolBrandProject({
        cate_id: 29,
        pageSize: 999999,
        pageNo: 1
      });
      this.list.push(...result.data);
      this.topData = this.list[0];
      this.loading = false;
      if (this.list.length >= result.totalCount) {
        this.finished = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.placed-the-top-project {
  background: #f2fbfa;
  padding-bottom: 7vw;
  .placed-the-top-project-cover {
    width: 100%;
    height: 62vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .placed-the-top-project-title {
    padding: 5vw 3vw;
    font-family: PingFang-SC-Bold;
    font-size: 4vw;
    color: #404040;
  }
  .placed-the-top-project-intro {
    padding: 0 3vw;
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    color: #838385;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .placed-the-top-project-time-bar {
    padding: 0 3vw;
    margin-top: 7vw;
    .placed-the-top-project-time {
      font-family: PingFang-SC-Regular;
      font-size: 3vw;
      color: #838385;
    }
  }
}

.brand-project-list {
  margin-top: 11vw;
}

.brand-project-item {
  padding-bottom: 6vw;
  border-bottom: 1vw solid #f0faf9;
  margin-bottom: 7vw;
  .brand-project-item-cover {
    width: 100%;
    height: 62vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .brand-project-item-title {
    margin-top: 5vw;
    font-family: PingFang-SC-Bold;
    font-size: 4vw;
    color: #404040;
  }
  .brand-project-item-time {
    margin: 4vw 0;
    font-family: PingFang-SC-Regular;
    font-size: 3vw;
    color: #a2a2a3;
  }
  .brand-project-item-intro {
    margin-bottom: 7vw;
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    line-break: strict;
    color: #404040;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .more-box {
    margin-top: 7vw;
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    color: #017069;
    .more-icon {
      margin-left: 2vw;
      width: 2vw;
    }
  }
}
.placed-the-top-project-more {
  .more {
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    color: #007069;
  }
  .more-iocn {
    margin-left: 2vw;
    width: 2vw;
  }
}
</style>
